import React from 'react'
import '../css/Icon.scss'

const baseUrl = '/Content/ReactSearcher/icons/'

export const icons = {
    barcelona:          baseUrl + 'barcelona.png',
    car:                baseUrl + 'car.png',
    date:               baseUrl + 'date.png',
    driver:             baseUrl + 'driver.png',
    hour:               baseUrl + 'hour.png',
    malaga:             baseUrl + 'malaga.png',
    tenerife:           baseUrl + 'tenerife.png',
    world:              baseUrl + 'world.png',  
    barcelonaActive:    baseUrl + 'barcelona-active.png',
    malagaActive:       baseUrl + 'malaga-active.png',
    tenerifeActive:     baseUrl + 'tenerife-active.png',
    worldActive:        baseUrl + 'world-active.png'
}


export default ({icon, className}) => {
    console.log('icon ', icon)
    return <img src={icon} className={className} />
}  


