import React, {useState} from 'react'
import ListOffices from './ListOffices'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

export default props => {
    const {
        offices,
        isVisible,
        setVisible,
        selectOffice
    } = props

    return (
        <div className='tab-offices'>
            <ListOffices offices={offices} selectOffice={selectOffice} />
        </div>
    )
}