import React, {useState} from 'react'
import Tabs from './Tabs'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Icon, {icons} from '../Icons'
import {inputTypes} from '../../const'

import '../../css/SelectOffice.scss'

export default props => {
    const {
        officeName,
        officesTenerife,
        selectOffice,
        forceClose,
        setActiveInput,
        inputType,
        style,
        translations
    } = props
    

    const [areTabsVisible, setAreTabsVisible] = useState(false)
    
    if(areTabsVisible && forceClose) setAreTabsVisible (false)

    const inputClicked = () => {
        setAreTabsVisible(!areTabsVisible)        
        setActiveInput(areTabsVisible ? inputTypes.none : inputType)
    }

    return (
        <div className="input-container" style={style}>
            <div className="selected-office" onClick={inputClicked}>
                <Icon icon={icons.car} className='icon-input' />
                <div className='office-name' >{officeName}</div>
                <FontAwesomeIcon icon='chevron-down' className='icon-input' />
            </div>                        
            
            {areTabsVisible && 
             <Tabs 
                translations={translations}
                className="tabs-offices" 
                officesTenerife={officesTenerife}
                selectOffice={selectOffice}
              /> 
            }
        </div>
    )
}