import React, { useRef, useState } from "react";
import classNames from "classnames";

import "../css/SelectTime.scss";
import { inputTypes } from "../const";
import Icon, { icons } from "./Icons";

// const hours = [...Array(24).keys()].map(hour => `${hour < 10 ? '0' : ''}${hour}:00`)

const hours = [
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
];

export default (props) => {
  const { hour, onChange, style, forceClose, setActiveInput, inputType } =
    props;

  const [isOpen, setIsOpen] = useState(false);
  const scrollRef = useRef(null);

  if (isOpen && forceClose) setIsOpen(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
    setActiveInput(isOpen ? inputTypes.none : inputType);

    setTimeout(() => {
      const index = hours.findIndex((h) => h === hour);
      if (scrollRef.current) scrollRef.current.scrollTo(0, index * 27);
    }, 300);
  };

  return (
    <div className="time-container" style={style} onClick={handleClick}>
      <Icon icon={icons.hour} className="time-icon" />
      <div className="hour-value">{hour}</div>
      {isOpen && (
        <div className="hours-container">
          <div className="hours-content" ref={scrollRef}>
            {hours.map((h) => (
              <div
                key={h}
                className={classNames(
                  "hour-item",
                  hour === h ? "selected" : ""
                )}
                onClick={() => onChange(h)}
              >
                {h}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
