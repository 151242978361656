import React, {useState} from 'react'
import classNames from 'classnames'
import TabOffices from './TabOffices'
import TabFreeSearch from './TabFreeSearch'
import Icon, {icons} from '../Icons'

export default props => {
    const {
        className,
        officesTenerife,
        selectOffice,
        translations
    } = props

    const tabsId = {
        tenerife:   'tenerife',
        world:      'world'
    }

    const [visibleTab, setVisibleTab] = useState(tabsId.tenerife)

    const headerItem = (id, icon, label) => 
        <div 
            tab-id={id}  
            className={classNames('tab-header-item', id == visibleTab ? 'selected' : '')}  
            onClick={() => setVisibleTab(id)} 
        >
            <Icon icon={icon} className='icon-place' />
            <div className='icon-label'>{label}</div>
        </div>

    console.log(visibleTab)

    return (
        <div className={className}>
            {tabsId.tenerife    === visibleTab && <TabOffices offices={officesTenerife} selectOffice={selectOffice} language={translations.language} /> }
            {tabsId.world       === visibleTab && <TabFreeSearch  selectOffice={selectOffice} translations={translations} /> }
        </div>
    )
}