import React, {useRef, useState} from 'react'
import '../css/Days.scss'

export default props => {    
    const {
        days,
        onChange,
        style
    } = props

    
    const handleChange = e => onChange({ 
        days: e.target.value,
    })
    

    /*
    <p className='days-content'>
                {days}
            </p>
    */
    return (
        <div className="days-container" style={style}>
            <input className='days-content' id="numberdays" type="text" pattern="[0-9]*" value={days}  onChange={(e) => {handleChange(e)}}></input>
        </div>
    )
}