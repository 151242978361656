import React, {useState, useRef, useEffect} from 'react';
import SelectOffice from './SelectOffice'
import SelectDate from './SelectDate'
import SelectTime from './SelectTime'
import SelectDays from './SelectDays'
import DriverAge from './DriverAge'
import ButtonSearch from './ButtonSearch';

import ValidateInput from '../helper/ValidateInputHelper'
import BuildInitialAppStatus from '../helper/BuildInitialAppStatus'
import ErrorModal from './ErrorsModal'

import {inputTypes, postUrl, postUrlWl} from '../const'

import {library} from '@fortawesome/fontawesome-svg-core'
import {
  faCar,
  faChevronDown,
  faCalendarAlt,
  faClock,
  faUser,
  faBuilding,
  faPlaneDeparture,
  faHotel,
  faSync
 } from '@fortawesome/free-solid-svg-icons'

import '../css/App.scss';

import dotenv, { parse } from 'dotenv'
dotenv.config()


library.add(faCar)
library.add(faChevronDown)
library.add(faCalendarAlt)
library.add(faClock)
library.add(faUser)
library.add(faBuilding)
library.add(faPlaneDeparture)
library.add(faHotel)
library.add(faSync)


export default props => {
  const {
    officesTenerife,
    translations,
    defaultParams
  } = props

  const formRefs = {
      Form                   : useRef(null),   
      ZonaBusqueda           : useRef(null),   
      DepDate                : useRef(null),
      HoraSalida             : useRef(null),
      OficinaSalida          : useRef(null),
      OficinaSalidaTXT       : useRef(null),
      otherHotelNamePickup   : useRef(null),
      PickupCityId           : useRef(null),
      PickupCountryId        : useRef(null),
      ZonaBusquedaReturn     : useRef(null),
      RetDate                : useRef(null),
      HoraRetorno            : useRef(null),
      DropoffCityId          : useRef(null),
      OficinaRetorno         : useRef(null),
      OficinaRetornoTXT      : useRef(null),
      OtherHotelNameDropoff  : useRef(null),
      DriverDateOdBirth      : useRef(null),
      DriverType             : useRef(null),
      Language               : useRef(null)
  }
  
  let formUrl = postUrl(translations.language)

  if (document.URL.indexOf('wl-') !== -1) 
  {
     formUrl = postUrlWl(translations.language)
  }

  const [state, setState]             = useState(BuildInitialAppStatus(defaultParams))
  const [errors, setErrors]           = useState(null)
  const [activeInput, setActiveInput] = useState(inputTypes.none)


  function checkDates(from, to) {
    var result = false
    if (parseInt(from) < parseInt(to)) {
      result = true;
    }
    return result;
  }
  
  function dateToNumber(date) {
    return date['year'].toString() +  pad_with_zeroes(date['month'].toString()-1,2) +  pad_with_zeroes(date['day'].toString(),2);
  }
  
  function pad_with_zeroes(number, length) {
    var result = '' + number;
    while (result.length < length) {
      result = '0' + result;
    }
    return result;
  }

  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

  function reStyle(){
    var element = document.getElementById("cars-container");
    if (element === undefined || element === null) 
    {
      
    }
    else{
      element.style.display = "none";
    }


  }

  function addDays(year,month,day, days) {
    var result = new Date(year,month,day);
    result.setDate(result.getDate() + parseInt(days));
    return result;
  }

  function cleanString (cadena)
  {
    var result = cadena;

    do {
      result = result.replace("+", " ");
    } while (result.includes("+"));

    return result;
  }

  const updateState = (newValues) => {
    if(newValues['days']) {
     var days = newValues['days'];
     var year = state['pickupDate']['year'].toString();
     var month = pad_with_zeroes(state['pickupDate']['month'].toString()-1,2);
     var day = pad_with_zeroes(state['pickupDate']['day'].toString(),2);
     var result = addDays(year,month,day,days);
     state['dropoffDate']['year'] = result.getFullYear();
     state['dropoffDate']['month'] = result.getMonth()+1;
     state['dropoffDate']['day'] = result.getDate();

     console.log(newValues);

    }
    
    if(newValues['pickupDate']) {
      if(dateToNumber(newValues['pickupDate']) > dateToNumber(state['dropoffDate'])) {
        var year = newValues['pickupDate']['year'].toString();
        var month = pad_with_zeroes(newValues['pickupDate']['month'].toString()-1,2);
        var day = pad_with_zeroes(newValues['pickupDate']['day'].toString(),2);
        var result = addDays(year,month,day,7);
        state['dropoffDate']['year'] = result.getFullYear();
        state['dropoffDate']['month'] = result.getMonth()+1;
        state['dropoffDate']['day'] = result.getDate();
        newValues['days'] = 7;
      }
      else {
        var to = state['dropoffDate']['year'].toString()+pad_with_zeroes(state['dropoffDate']['month'].toString(),2)+pad_with_zeroes(state['dropoffDate']['day'].toString(),2);
        var from = newValues['pickupDate']['year'].toString()+pad_with_zeroes(newValues['pickupDate']['month'].toString(),2)+pad_with_zeroes(newValues['pickupDate']['day'].toString(),2);
        var result = checkDates(from,to);
        if (result) {
          var dayFrom = newValues['pickupDate']['day'];
          var monthFrom = newValues['pickupDate']['month'];
          var yearFrom = newValues['pickupDate']['year'];
          var dateFrom = new Date(yearFrom,monthFrom,dayFrom);
          var dayTo = state['dropoffDate']['day'];
          var monthTo = state['dropoffDate']['month'];
          var yearTo = state['dropoffDate']['year'];
          var dateTo = new Date(yearTo,monthTo,dayTo);
          const diffTime = Math.abs(dateFrom - dateTo);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
          newValues['days'] = diffDays;
        }
        else 
          newValues['days'] = 0;
      }

      defaultParams.pickupTime = '16:00';
    }

    if(newValues['dropoffDate']) {
      var to = newValues['dropoffDate']['year'].toString()+pad_with_zeroes(newValues['dropoffDate']['month'].toString(),2)+pad_with_zeroes(newValues['dropoffDate']['day'].toString(),2);
      var from = state['pickupDate']['year'].toString()+pad_with_zeroes(state['pickupDate']['month'].toString(),2)+pad_with_zeroes(state['pickupDate']['day'].toString(),2);
      var result = checkDates(from,to);
      if (result) {
        var dayFrom = state['pickupDate']['day'];
        var monthFrom = state['pickupDate']['month'];
        var yearFrom = state['pickupDate']['year'];
        var dateFrom = new Date(yearFrom,monthFrom,dayFrom);
        var dayTo = newValues['dropoffDate']['day'];
        var monthTo = newValues['dropoffDate']['month'];
        var yearTo = newValues['dropoffDate']['year'];
        var dateTo = new Date(yearTo,monthTo,dayTo);
        const diffTime = Math.abs(dateFrom - dateTo);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
        newValues['days'] = diffDays;
      }
      else 
        newValues['days'] = 0;
    }

    const newState = Object.assign({}, state, newValues)    
    setState(newState)
    setActiveInput(inputTypes.none)
  }



  const submitSearch = () => {

    const errors = ValidateInput.check(state, formRefs, translations)

    if(errors.length > 0){
      setErrors(errors)
      return
    }

    formRefs.Form.current.submit()
  }

  console.log('state ', state)

  return (
    <div id='searcher'>
         <div id="pickOffice">
          <label className='labelSpace'>{decodeURI(translations.pickupOffice)}</label>
          <SelectOffice 
              style={{gridArea: 'pickup-office'}}
              onClick={() => setActiveInput(inputTypes.pickupOffice)}
              isPickup={true}
              officeId={31902}
              officeName={decodeURI(cleanString(state.pickupOffice.name)) || translations.pickupPlaceholder}
              officesTenerife={officesTenerife}
              selectOffice={value => {
                const newValues = {pickupOffice: value}
                newValues.dropoffOffice = value
                updateState(newValues)
              }}
              forceClose={activeInput != inputTypes.pickupOffice}
              setActiveInput={setActiveInput}
              inputType={inputTypes.pickupOffice}
              translations={translations}
          /> 
        </div>

      <div id='pickOfficeDates'>
      <label className='labelSpace'>{translations.datePickup}</label> 
      <div id="cajaFechas1">
      <SelectDate
          language={translations.language} 
          style={{gridArea: 'pickup-date'}}
          date={state.pickupDate}          
          forceClose={activeInput != inputTypes.pickupDate}
          setActiveInput={setActiveInput}
          inputType={inputTypes.pickupDate}
          onClick={reStyle()}
          onChange={value => updateState({pickupDate: value})}          
      />
      <SelectTime       
        style={{gridArea: 'pickup-time'}}
        hour={state.pickupTime}
        forceClose={activeInput != inputTypes.pickupTime}
        setActiveInput={setActiveInput}
        inputType={inputTypes.pickupTime}
        onChange={value => updateState({pickupTime: value})}
      /> 

      </div>

      </div>
      <div id="daysBox">
      <label className='labelSpace'>{decodeURI(translations.daysM)}</label>
      <p id="daysLiteral">{decodeURI(translations.reservationLeyend1)} <strong>{state.days} {decodeURI(translations.days)}</strong></p>
      <SelectDays   
        id="selectorDias"    
        style={{gridArea: 'days'}}
        days={state.days}
        onChange={value => updateState({days:value.days})}
      /> 
     </div>

    <div id="pickOffOffice">
    <label className='labelSpace'>{decodeURI(translations.dropoffOffice)}</label>
      <SelectOffice 
            style={{gridArea: 'dropoff-office'}}
            onClick={() => setActiveInput(inputTypes.dropoffOffice)}
            isDropOff={true}
            officeId={31902}
            officeName={decodeURI(cleanString(state.dropoffOffice.name)) || translations.dropoffPlaceholder}
            officesTenerife={officesTenerife}
            selectOffice={value => updateState({dropoffOffice: value})}
            forceClose={activeInput != inputTypes.dropoffOffice}
            setActiveInput={setActiveInput}
            inputType={inputTypes.dropoffOffice}
            translations={translations}
        /> 
      </div>

<div id='pickOfficeDatesReturn'>

<label className='labelSpace'>{decodeURI(translations.dateDropOff)}</label> 

      <div id="cajaFechas2">

      <SelectDate 
          language={translations.language}
          style={{gridArea: 'dropoff-date'}}
          date={state.dropoffDate}          
          forceClose={activeInput != inputTypes.dropoffDate}
          setActiveInput={setActiveInput}
          inputType={inputTypes.dropoffDate}
          onClick={reStyle()}
          onChange={value => updateState({dropoffDate: value})}
      /> 

      <SelectTime 
        style={{gridArea: 'dropoff-time'}}
        hour={state.dropoffTime}
        forceClose={activeInput != inputTypes.dropoffTime}
        setActiveInput={setActiveInput}
        inputType={inputTypes.dropoffTime}
        onChange={value => updateState({dropoffTime: value})}
      />

      </div>

</div>


<div id="driverBox">
      <label className='labelSpace'>{translations.age}</label> 
      <div id="driverplain">
       <DriverAge 
        style={{gridArea: 'driver-age'}}
        age={state.driverAge}
        minAge={state.minDriverAge}
        driverType={state.driverType}        
        forceClose={activeInput != inputTypes.userAge}
        setActiveInput={setActiveInput}
        inputType={inputTypes.userAge}
        onChange={value => updateState({driverType: value})}
        textRegularDriver={translations.textRegularDriver}
        textYoungDriver21={translations.textYoungDriver21}
        textYoungDriver23={translations.textYoungDriver23}
        DriverLeyend={translations.age}        
      />
    {/* <div id="cerca">
   <ButtonSearch onClick={submitSearch} active={!errors} text={translations.textSearchButton} /> 
   </div> */}
   </div>

</div>

<div id="cerca2">
<ButtonSearch id="btncerca2" onClick={submitSearch} active={!errors} text={translations.textSearchButton} /> 
</div>

      

      <form ref={formRefs.Form} style={{ display: 'none'}} action={formUrl} method='post'>
          <input type='hidden' ref={formRefs.ZonaBusqueda}          name='zonaBusqueda' />
          <input type='hidden' ref={formRefs.DepDate}               name='depDate' />
          <input type='hidden' ref={formRefs.HoraSalida}            name='horaSalida' />
          <input type='hidden' ref={formRefs.OficinaSalida}         name='oficinaSalida' />
          <input type='hidden' ref={formRefs.OficinaSalidaTXT}      name='oficinaSalidaTXT' />
          <input type='hidden' ref={formRefs.otherHotelNamePickup}  name='otherHotelNamePickup' />
          <input type='hidden' ref={formRefs.PickupCityId}          name='pickupCityId' />
          <input type='hidden' ref={formRefs.PickupCountryId}       name='pickupCountryId' />
          <input type='hidden' ref={formRefs.ZonaBusquedaReturn}    name='zonaBusquedaReturn' />
          <input type='hidden' ref={formRefs.RetDate}               name='retDate' />
          <input type='hidden' ref={formRefs.HoraRetorno}           name='horaRetorno' />
          <input type='hidden' ref={formRefs.DropoffCityId}         name='dropoffCityId' />
          <input type='hidden' ref={formRefs.OficinaRetorno}        name='oficinaRetorno' />
          <input type='hidden' ref={formRefs.OficinaRetornoTXT}     name='oficinaRetornoTXT' />
          <input type='hidden' ref={formRefs.OtherHotelNameDropoff} name='otherHotelNameDropoff' />
          <input type='hidden' ref={formRefs.DriverDateOdBirth}     name='driverDateOdBirth' />
          <input type='hidden' ref={formRefs.DriverType}            name='DriverType' />          
          <input type='hidden' ref={formRefs.Language}              name='language' value={translations.language} />

          <input type="submit" value="" name="btnSubmit" id="searchSubmitButton" />
      </form> 

      <ErrorModal 
          errors={errors}
          close={() => setErrors(null)}
          textClose={translations.errors.closeWindow}
          textTitle={translations.errors.missPickupLocation.title}
      /> 
    </div>    
  )
}
