export const inputTypes = {
    none:           'none',
    pickupOffice:   'pickupOffice',
    pickupDate:     'pickupDate',
    pickupTime:     'pickupTime',
    dropoffOffice:  'dropoffOffice',
    dropoffDate:    'dropoffDate',
    dropoffTime:    'dropoffTime',
    userAge:        'userAge'
}

const baseUrl = lang => {

  return (process.env.NODE_ENV === 'development' ? 'http://localhost:17245/' : '/') +
         (lang === 'es' ? '': `${lang}/`)
}
  export const postUrl = lang => baseUrl(lang) + 'Booking/Availability_3'
  export const postUrlWl = lang => baseUrl(lang) + 'wl-/Booking/Availability_3'
  export const autocompleteUrl = lang => baseUrl(lang) + 'Booking/AutocompleteWorld'


