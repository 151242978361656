import React, {useState} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

export default props => {
    const {
        offices,
        selectOffice,
        showZone = true
    } = props

    const handleSelectOffice = office =>     {
        selectOffice(office)
    }

    return (
        <div className='list-offices'>
            {offices && offices.map((office, i) =>
                <div className='office-item' onClick={() => handleSelectOffice(office)} key={i}>
                    <div className='office-icon'>
                        {office.type == 'Aeropuerto' && <FontAwesomeIcon icon="plane-departure" />}
                        {office.type == 'Hotel'   && <FontAwesomeIcon icon="hotel" />}
                        {office.type == 'Oficina'  && <FontAwesomeIcon icon="building" />}
                    </div>
                    <div className='office-name'>{office.name}</div>
                    {showZone && <div className='office-zone'>{office.city}</div>}
                </div>    
            )}
        </div>
    )
}

//{name: 'aeropuerto sur',      icon: 'airport',  zone: 'airport',      id: '30902'},