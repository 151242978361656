import React, {useState, useEffect} from 'react'
import ListOffices from './ListOffices'
import axios from 'axios'
import PlacesAutocomplete, { geocodeByPlaceId } from 'react-places-autocomplete'
import {autocompleteUrl} from '../../const'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'



export default props => {
    const {
        selectOffice,
        translations
    } = props

    
    const [inputValue, setInputValue] = useState('')
    const [firstSuggestion, setFirstSuggestion] = useState('')
    const [state, setState] = useState({ ['-']: {locations: []}, loading: false})

    useEffect(() => {

        async function _(){     
            setState(Object.assign({}, state, { loading: true}))

            const{
                data: {
                    Locations,
                    errorId,
                    errorMessage
                }            
            } =  await axios.get(autocompleteUrl(translations.language),
                                { 
                                    params: { 
                                        term: firstSuggestion, 
                                        token: '', 
                                        language: translations.language 
                                    } 
                                })

            const locations = errorId 
                ? [] 
                : Locations.map(location => ({
                    city:               location.Address,
                    id:                 location.IdOficina, 
                    idCity:             location.CityId,
                    idZone:             location.IdOficina,
                    name:               location.Name,
                    pickupCountryId:    location.CountryId
                }))                

            setState(Object.assign({}, state, { [firstSuggestion]: { locations }, loading: false}))
            
        }

        if(firstSuggestion?.length >= 3) _()

    }, [firstSuggestion])


    function handleGoogleSuggestions(suggestions){
        const firstSuggestion = !!suggestions && 
                                !!suggestions[0] && 
                                !!suggestions[0].description 
                                    ? suggestions[0].description.split(',')[0]
                                    : null
        if(firstSuggestion)
                setFirstSuggestion(firstSuggestion)          
    }

    console.log('firstSuggestion: ' + firstSuggestion)

    return (
        <div className='tab-offices free-search-tab'>
            <div className='autocomplete-container'>
                 <PlacesAutocomplete            
                    value={inputValue}
                    onChange={val => setInputValue(val)}
                    onSelect={() => null}
                    debounce={500}
                    shouldFetchSuggestions={({value}) => value.length >= 3 }
                >
                    {({getInputProps, suggestions, loading}) => { 
                       handleGoogleSuggestions(suggestions)
                                
                        return <input 
                                    {...getInputProps({
                                        placeholder: translations.searchForAPlace,
                                        className: 'google-autocomplete'
                                    })}
                                />
                    }}            
                </PlacesAutocomplete>
            </div>
            {state.loading 
                ? <div className='spin-container'><FontAwesomeIcon icon='sync' className='fa-spin' /></div>
                : <ListOffices offices={state[firstSuggestion]?.locations} selectOffice={selectOffice} showZone={false} /> 
            }
        </div>
    )
}
