import React from 'react'
import Icon, {icons} from './Icons'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from "moment"
import MomentUtils from '@date-io/moment'
import "moment/locale/es"
import "moment/locale/en-gb"
import "moment/locale/de"
import "moment/locale/fr"
import "moment/locale/it"
import "moment/locale/ru"
import "moment/locale/nb"
import "moment/locale/cs"

import '../css/SelectDate.scss'

export default props => {
    const {
        date,
        style,
        onChange,
        setActiveInput,
        inputType,
        language
    } = props

    const handleClick = date => onChange({ 
        day: parseInt(date.format('D')),
        month: parseInt(date.format('M')),
        year: parseInt(date.format('YYYY'))
    })

       
    moment.locale(language)


    return (
        <div className="date-container" style={style}>
            <MuiPickersUtilsProvider utils={MomentUtils} locale={language}>
            <label className="date-label">
                <Icon icon={icons.date} className="date-icon" />
                <DatePicker 
                    value={new Date(date.year, date.month -1, date.day)} 
                    onChange={handleClick} 
                    onOpen={() => setActiveInput(inputType)}
                    format="ddd DD/MM"
                    disablePast={true}
                    autoOk={true}
                    InputProps={{
                        disableUnderline: true,
                       }}
                />
                </label>
            </MuiPickersUtilsProvider>
            
        </div>
    )
}