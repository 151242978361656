import { relativeTimeRounding } from 'moment'
import React from 'react'
import Modal from 'react-modal'
import '../css/ErrorsModal.scss'


Modal.setAppElement('#root')

const overlayStyle = {
    backgroundColor: 'rgba(0,0,0,0.75)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}

const contentStyle = {
    right: 'auto',
    bottom: 'auto',
    top: 'auto',
    left: 'auto',
    position: 'relative',
    minWidth: '50%',
    maxWidth: '80%'
}


export default props => {
    const {
        errors,
        close,
        textClose,
        textTitle
    } = props

    return (
        <Modal
            isOpen={!!errors}
            onRequestClose={close}
            style={{ overlay: overlayStyle, content: contentStyle}}
        >
            <div className='error-title'>
            <h2>{textTitle}</h2>
                <div className='error-close' onClick={close}>{textClose}</div>
            </div>
            

            {errors && errors.length > 0 && errors.map(error => <div className='error-item'>{error}</div>)}
        </Modal>
    )
}