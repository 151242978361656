import React, {useState} from 'react'
import '../css/ButtonSearch.scss'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

export default props => {
    const {
        onClick,
        active,
        text
    } = props

    const [isClicked, setIsClicked] = useState(false)

    const handleClick = () => {
        setIsClicked(true)
        onClick()
    }

    if(!active && isClicked) setIsClicked(false)

    return (
        <div className='button-search-container' onClick={handleClick}>
            <div className='button-search-content'>                
                {isClicked && active? 
                    <FontAwesomeIcon icon='sync' className='fa-spin' />
                 : text }
            </div>            
        </div>
    )
}